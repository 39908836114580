import ReactGA4 from 'react-ga4';

const initializeGA4 = async () => {
    await ReactGA4.initialize(process.env.REACT_APP_GTM_CONTAINER_ID);
  };
  
  initializeGA4();

export const captureEvents = async ({event, custom = {}}) => {
    try {
        ReactGA4.event(event, custom);
    } catch (error) {   
        // console.log(error)
    }
}